import React from "react";
import { useScroll } from "../hooks/useScroll";

import MiniLogo from "../assets/MiniLogo.svg";
import Mail from "../assets/Mail.svg";

export default function Navbar() {
  const mailHandler = () => {
    window.open("mailto:contact@martingale.consulting");
  };

  const { scrollDirection } = useScroll();

  const styles = {
    active: "opacity-1 transition duration-250 ease-in-out",
    inactive: "opacity-0 transition duration-250 ease-in-out",
  };

  return (
    <div className={(scrollDirection === "up" & window.scrollY > 0)  ? styles.inactive : styles.active}>
      <div className="z-20 fixed top-0 flex w-screen py-6 px-6 lg:py-3 sm:px-14 justify-between bg-green-dark">
        <img src={MiniLogo} alt="Mini Logo" className="w-12 xl:w-20 2xl:w-28" />
        <img
          src={Mail}
          alt="Mail"
          onClick={mailHandler}
          className="cursor-pointer w-12 lg:w-16"
        />
      </div>
    </div>
  );
}
